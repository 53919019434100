import { Col, Divider, Form, Input, Row, Select, Spin, Switch } from 'antd'
import React, { Component, Fragment } from 'react'
import { CreatePhoneMask, ValidateEmail } from '../../../../../utils/mask'
import { findAll, findWhere } from '../../../../../utils/db_functions'
import ContactsFields from '../contacts-fields'
import ReactQuill from 'react-quill'

export default class ProjectStep extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            dataConfigs: {},
            performanceData: {},
            providerData: [],
            occupations: [],
            servicePackages: [],
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true })

        const [dataConfigsArray, servicePackages] = await Promise.all([
            findWhere('Config', 'name', ['ocupation', 'proposal_form_notes_projeto']),
            findAll('ArchitecturalServices'),
        ]);

        const dataConfigs = dataConfigsArray.reduce((acc, value) => {
            acc[value.get('name')] = value.get('value')
            return acc;
        }, {});

        const profilePerfomances = this.props.dataConfigs['project-profilePerfomances'] || await findAll('ProfilePerformance')
        let performanceData = {}
        profilePerfomances.forEach(profilePerformance => {
            performanceData[profilePerformance.get('profile_performance')] = profilePerformance.get('performance_level')
        })

        const occupations = this.props.dataConfigs['project-occupations'] || await findAll('Occupation')
        const providerData = this.props.dataConfigs['project-providerData'] || await findAll('Provider')

        this.setState({
            loading: false, dataConfigs, performanceData, providerData, occupations, servicePackages
        })

        this.props.setDataConfigs({
            'project-profilePerfomances': profilePerfomances, 'project-occupations': occupations,
        })
    }

    usageValues(type) {
        let allOccupations = {};
        (this.state.occupations || []).forEach(occupation => {
            allOccupations[occupation.get('description')] = occupation.get('usage_types')
        })
        return allOccupations[type] || [];
    }

    render() {
        return (
            <div>
                <Spin spinning={this.state.loading} size='large' tip='Carregando as informações...'>
                    <Row gutter={24}>
                        <Col span={16}>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Ocupação" required>
                                        <Select value={this.props.getValue('ocupation')} onChange={value => {
                                            this.props.updateDataValue('ocupation', value)
                                            this.props.updateDataValue('usage', null)
                                        }} placeholder="Ocupação" size="large" disabled={this.props.disabledFields}>
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            {(this.state.dataConfigs.ocupation || []).map((ocupation, index) => {
                                                return (
                                                    <Select.Option key={index} value={ocupation}>{ocupation}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Uso">
                                        <Select disabled={!this.props.getValue('ocupation') || this.props.disabledFields} value={this.props.getValue('usage')} onChange={value => this.props.updateDataValue('usage', value)} placeholder="Uso" size="large">
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            {(this.usageValues(this.props.getValue('ocupation')) || []).map((usage, index) => {
                                                return (
                                                    <Select.Option key={index} value={usage}>{usage}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Nível de desempenho" required>
                                        <Select value={this.props.getValue('performance_level')} onChange={value => this.props.updateDataValue('performance_level', value)} placeholder="Nível de desempenho" size="large" disabled={this.props.disabledFields}>
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            {(Object.keys(this.state.performanceData) || []).map((performanceLevel, index) => {
                                                return (
                                                    <Select.Option key={index} value={performanceLevel}>{performanceLevel}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>

                                <Col span={2}>
                                    <Form.Item label="FSC">
                                        <Switch checked={this.props.getValue('fsc')} onChange={value => this.props.updateDataValue('fsc', value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Fornecedor/Marca">
                                        <Select value={this.props.getValue('provider')} onChange={value => this.props.updateDataValue('provider', value)} placeholder="Fornecedor/Marca" size="large" disabled={this.props.disabledFields}>
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            {(this.state.providerData || []).map((ParseWorkStage, regIndex) => {
                                                return (
                                                    <Select.Option key={regIndex} value={ParseWorkStage.id}> {ParseWorkStage.get('name')} </Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Especificação de projeto">
                                        <Select value={this.props.getValue('project_especs')} onChange={value => this.props.updateDataValue('project_especs', value)} placeholder="Especificação de Projeto" size="large" disabled={this.props.disabledFields}>
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            <Select.Option
                                                // @ts-ignore
                                                value={true}
                                            >Sim</Select.Option>
                                            <Select.Option
                                                // @ts-ignore
                                                value={false}
                                            >Não</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {this.props.getValue('project_especs') ?
                                <Fragment>
                                    <Divider>Especificação de Projeto</Divider>
                                    <Row gutter={24}>
                                        <Col span={6}>
                                            <Form.Item label="Escritório">
                                                <Input
                                                    value={this.props.getValue('office')}
                                                    onChange={event => this.props.updateDataValue('office', event.target.value)}
                                                    placeholder="Escritório" size="large" disabled={this.props.disabledFields} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Endereço">
                                                <Input
                                                    value={this.props.getValue('office_address')}
                                                    onChange={event => this.props.updateDataValue('office_address', event.target.value)}
                                                    placeholder="Endereço" size="large" disabled={this.props.disabledFields} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Arquiteto">
                                                <Input
                                                    value={this.props.getValue('architect')}
                                                    onChange={event => this.props.updateDataValue('architect', event.target.value)}
                                                    placeholder="Arquiteto" size="large" disabled={this.props.disabledFields} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Contato">
                                                <Input
                                                    value={this.props.getValue('architect_contact')}
                                                    onChange={event => this.props.updateDataValue('architect_contact', event.target.value)}
                                                    placeholder="Contato" size="large" disabled={this.props.disabledFields} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Fragment> : null
                            }
                        </Col>

                        <Col span={8}>
                            <div className='note-box'>
                                <div className='note-title'>Instruções:</div>
                                <div>{
                                    ((this.state.dataConfigs && this.state.dataConfigs.proposal_form_notes_projeto) || [])
                                        .map(paragraph => <p>{paragraph}</p>)
                                }</div>
                            </div>
                        </Col>
                    </Row>

                    <Divider />

                    <h2>Contato de projeto</h2>

                    {
                        Date.parse(this.props.getValue('createdAt')) > Date.UTC(2022, 5, 2) ? // Data da atualização da lógica dos campos de contatos
                            <ContactsFields
                                contactKey='project'
                                value={this.props.getValue('projectContacts')}
                                updateContacts={value => this.props.updateDataValue('projectContacts', value)}
                            />
                            :
                            <Fragment>

                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item label="Contato de arquitetura">
                                            <Input
                                                value={this.props.getValue('contact_project_arq')}
                                                onChange={event => this.props.updateDataValue('contact_project_arq', event.target.value)} placeholder="Contato de arquitetura" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Cargo">
                                            <Input
                                                value={this.props.getValue('cargo_project_arq')}
                                                onChange={event => this.props.updateDataValue('cargo_project_arq', event.target.value)} placeholder="Cargo" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Telefone">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_project_arq'))} onChange={event => this.props.updateDataValue('phone_project_arq', event.target.value)} placeholder="Telefone" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Celular">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_cel_project_arq'))} onChange={event => this.props.updateDataValue('phone_cel_project_arq', event.target.value)} placeholder="Celular" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Whatsapp">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_whatsapp_project_arq'))} onChange={event => this.props.updateDataValue('phone_whatsapp_project_arq', event.target.value)} placeholder="Whatsapp" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <
                                            // @ts-ignore
                                            Form.Item label="E-mail" {...ValidateEmail(this.props.getValue('email_project_arq') || '')}>
                                            <Input
                                                value={this.props.getValue('email_project_arq')}
                                                onChange={event => this.props.updateDataValue('email_project_arq', event.target.value)} placeholder="E-mail" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item label="Contato de especificação">
                                            <Input
                                                value={this.props.getValue('contact_project_espec')}
                                                onChange={event => this.props.updateDataValue('contact_project_espec', event.target.value)} placeholder="Contato de especificação" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Cargo">
                                            <Input
                                                value={this.props.getValue('cargo_project_espec')}
                                                onChange={event => this.props.updateDataValue('cargo_project_espec', event.target.value)} placeholder="Cargo" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Telefone">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_project_espec'))} onChange={event => this.props.updateDataValue('phone_project_espec', event.target.value)} placeholder="Telefone" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Celular">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_cel_project_espec'))} onChange={event => this.props.updateDataValue('phone_cel_project_espec', event.target.value)} placeholder="Celular" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Whatsapp">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_whatsapp_project_espec'))} onChange={event => this.props.updateDataValue('phone_whatsapp_project_espec', event.target.value)} placeholder="Whatsapp" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <
                                            // @ts-ignore
                                            Form.Item label="E-mail" {...ValidateEmail(this.props.getValue('email_project_espec') || '')}>
                                            <Input
                                                value={this.props.getValue('email_project_espec')}
                                                onChange={event => this.props.updateDataValue('email_project_espec', event.target.value)} placeholder="E-mail" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item label="Contato de compatibilização">
                                            <Input
                                                value={this.props.getValue('contact_project_compat')}
                                                onChange={event => this.props.updateDataValue('contact_project_compat', event.target.value)} placeholder="Contato de compatibilização" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Cargo">
                                            <Input
                                                value={this.props.getValue('cargo_project_compat')}
                                                onChange={event => this.props.updateDataValue('cargo_project_compat', event.target.value)} placeholder="Cargo" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Telefone">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_project_compat'))} onChange={event => this.props.updateDataValue('phone_project_compat', event.target.value)} placeholder="Telefone" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Celular">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_cel_project_compat'))} onChange={event => this.props.updateDataValue('phone_cel_project_compat', event.target.value)} placeholder="Celular" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Whatsapp">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_whatsapp_project_compat'))} onChange={event => this.props.updateDataValue('phone_whatsapp_project_compat', event.target.value)} placeholder="Whatsapp" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <
                                            // @ts-ignore
                                            Form.Item label="E-mail" {...ValidateEmail(this.props.getValue('email_project_compat') || '')}>
                                            <Input
                                                value={this.props.getValue('email_project_compat')}
                                                onChange={event => this.props.updateDataValue('email_project_compat', event.target.value)} placeholder="E-mail" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Fragment>
                    }

                    <Divider />

                    <h2 style={{ marginBottom: 20 }}>Pacote para Retrofit</h2>
                    <ReactQuill
                        value={this.props.getValue("retrofitPackage") || ''}
                        modules={{
                            toolbar: [
                                [{ 'header': [1, 2, 3, false] }],
                                ['bold', 'italic', 'underline', 'link', { 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }]
                            ],
                        }}
                        formats={[
                            'header',
                            'bold', 'italic', 'underline', 'link', 'align',
                            'list', 'bullet'
                        ]}
                        onChange={event => this.props.updateDataValue('retrofitPackage', event)}
                    />

                    <Divider />

                    <h2 style={{ marginBottom: 20 }}>Pacote para Construção</h2>
                    <ReactQuill
                        value={this.props.getValue("constructionPackage") || ''}
                        modules={{
                            toolbar: [
                                [{ 'header': [1, 2, 3, false] }],
                                ['bold', 'italic', 'underline', 'link', { 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }]
                            ],
                        }}
                        formats={[
                            'header',
                            'bold', 'italic', 'underline', 'link', 'align',
                            'list', 'bullet'
                        ]}
                        onChange={event => this.props.updateDataValue('constructionPackage', event)}
                    />
                </Spin>
            </div>
        )
    }

}