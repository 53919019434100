import React from 'react';
import { Divider, Select, Tag, Icon, Modal, Input } from 'antd';
import '../../styles/kit.css';
import { findAll, findWhere, findWhereMultiple } from '../../../utils/db_functions';
import MassChangeModal from './entities/mass-changes-modal';
// import { changeToArray } from '../../../utils/data-db-manipulation.js';

// Variables
let checklists = [];
let matrizDoorLocks = { fechaduras: [], ferragens: [] };
let matrizAliz = { aliz: [], alizWithBand: [], alizWithPanel: [] };
let matrizPanels = { panel_6mm: [], panel_15mm: [], panel_18mm: [], panel_steel_frame: [] };

/**
 * Find realated alizares in a matriz
 * @returns array of parse objects
 */
const getAlizInMatriz = async ({ performance, typology, width, height, sheet_qtd, comp_marco }) => {

  const alizMatriz = await findWhereMultiple('MatrizAliz', [
    { field: 'performance', value: [performance], op: 'containsAll' }, { field: 'typology', value: typology, op: 'containsAll' },
    { field: 'sheet_width', value: width, op: 'containsAll' }, { field: 'sheet_height', value: height, op: 'equalTo' },
    { field: 'sheet_qtd', value: sheet_qtd, op: 'equalTo' },
  ], null, ['component_kit', 'component_kit_fusion_frame', 'component_kit_with_band', 'component_kit_with_band_fusion_frame', 'component_kit_with_panel', 'component_kit_with_panel_fusion_frame']);

  let notShowAlizFlag = false
  if (comp_marco.length) {
    const countMarcoNotWood = comp_marco.filter(marco => marco.get('description_component').toLowerCase().includes('aço')).length
    notShowAlizFlag = countMarcoNotWood === comp_marco.length
  }

  const aliz = notShowAlizFlag ? [] : alizMatriz.length > 0 ? [...(alizMatriz[0].get('component_kit') || []), ...(alizMatriz[0].get('component_kit_fusion_frame') || [])] : [];
  const alizWithBand = notShowAlizFlag ? [] : alizMatriz.length > 0 ? [...(alizMatriz[0].get('component_kit_with_band') || []), ...(alizMatriz[0].get('component_kit_with_band_fusion_frame') || [])] : [];
  const alizWithPanel = notShowAlizFlag ? [] : alizMatriz.length > 0 ? [...(alizMatriz[0].get('component_kit_with_panel') || []), ...(alizMatriz[0].get('component_kit_with_panel_fusion_frame') || [])] : [];

  return { aliz, alizWithBand, alizWithPanel };
}

/**
 * Find related door locks in a matriz
 * @returns array of parse objects
 */
const getFechaduraInMatriz = async ({ performance, typology }) => {
  const fechMatriz = await findWhereMultiple('MatrizDoorLocks', [
    { field: 'performances', value: [performance], op: 'containsAll' }, { field: 'typology', value: typology, op: 'containsAll' },
  ], null, ['component_kit_fechadura', 'component_kit_ferragens']);
  const fechaduras = fechMatriz.length > 0 ? fechMatriz[0].get('component_kit_fechadura') : [];
  const ferragens = fechMatriz.length > 0 ? fechMatriz[0].get('component_kit_ferragens') : [];
  return { fechaduras, ferragens };
}

/**
 * Find related panels in a matriz
 * @returns array of parse objects
 */
const getPanelInMatriz = async ({ performance, typology }) => {
  const panelMatriz = await findWhereMultiple('MatrizPanel', [
    { field: 'performances', value: [performance], op: 'containsAll' }, { field: 'typology', value: typology, op: 'containsAll' },
  ], null, ['panel_6mm', 'panel_15mm', 'panel_18mm', 'steel_frame_panel']);
  const panel_6mm = panelMatriz.length > 0 ? panelMatriz[0].get('panel_6mm') : [];
  const panel_15mm = panelMatriz.length > 0 ? panelMatriz[0].get('panel_15mm') : [];
  const panel_18mm = panelMatriz.length > 0 ? panelMatriz[0].get('panel_18mm') : [];
  const panel_steel_frame = panelMatriz.length > 0 ? panelMatriz[0].get('steel_frame_panel') : [];
  return { panel_6mm, panel_15mm, panel_18mm, panel_steel_frame };
}

let typologies = [];

// Module component
const KitModule = {
  FormHasMounted: async (_) => {
    const matrizFech = await getFechaduraInMatriz(_.state.formRef);
    matrizDoorLocks = {
      fechaduras: matrizFech['fechaduras'],
      ferragens: matrizFech['ferragens'],
    };

    matrizAliz = await getAlizInMatriz(_.state.formRef);

    const matrizPanel = await getPanelInMatriz(_.state.formRef);
    matrizPanels = {
      panel_6mm: matrizPanel['panel_6mm'],
      panel_15mm: matrizPanel['panel_15mm'],
      panel_18mm: matrizPanel['panel_18mm'],
      panel_steel_frame: matrizPanel['panel_steel_frame'],
    };

    checklists = await findAll('ChecklistConfigs', 300);
    _.forceUpdate();
  },
  ListWillMount: async () => {
    const typologiesParseArray = await findWhere('Config', 'name', ['typology']);
    typologies = typologiesParseArray.length && typologiesParseArray[0].get('array_with_id');
  },
  fields: [
    [
      {
        title: "Código Kit",
        key: "code",
        type: "text",
        minLength: 20,
        maxLength: 20,
        colSize: 6,
        errorMessage: "O Código Kit deve conter 20 caracteres.",
        "element-attr": {
          "required": true,
          "prefix": <Icon type="code-o" />,
          maxlength: 20
        }
      },
      {
        type: 'text',
        key: "name",
        title: "Descrição do kit",
        colSize: 14,
        "element-attr": {
          required: true
        }
      },
      {
        "title": "Imagem do Kit",
        "key": "photo",
        "type": "image",
        "className": "upload-image-user",
        "element-attr": { "required": false }
      },
    ],
    [
      {
        type: 'select',
        key: 'typology',
        title: 'Tipologia',
        options: [],
        optionConfig: { name: 'typology', arrayWithId: true },
        loaded: false,
        "element-attr": {
          mode: 'multiple',
          required: true,
          afterChange: async (_) => {
            matrizAliz = await getAlizInMatriz(_.state.formRef);

            const matrizFech = await getFechaduraInMatriz(_.state.formRef);
            matrizDoorLocks = {
              fechaduras: matrizFech['fechaduras'],
              ferragens: matrizFech['ferragens'],
            };

            const matrizPanel = await getPanelInMatriz(_.state.formRef);
            matrizPanels = {
              panel_6mm: matrizPanel['panel_6mm'],
              panel_15mm: matrizPanel['panel_15mm'],
              panel_18mm: matrizPanel['panel_18mm'],
              panel_steel_frame: matrizPanel['panel_steel_frame'],
            };

            _.forceUpdate();
          }
        },
        colSize: 7,
        render: (options) => {
          let opt = options.reduce((acc, v) => {
            let type = v.label.split(' ')[0]
            if (!acc[type]) {
              acc[type] = {
                children: []
              }
            }
            acc[type].children.push(v)
            return acc;
          }, {});
          let types = Object.keys(opt)
          return types.map((type, index) => {
            return (
              <Select.OptGroup label={types[index]} key={`group-${index}`}>
                {opt[type].children.map((v, vI) => <Select.Option key={`group-${index}=${vI}`} value={v.value}>{v.label}</Select.Option>)}
              </Select.OptGroup>
            )
          })
        }
      },
      {
        type: 'select',
        key: 'performance',
        title: 'Desempenho do kit',
        options: [],
        optionConfig: { name: 'performance' },
        loaded: false,
        colSize: 3,
        'element-attr': {
          required: true,
          onChangeOverride: async (Form, _, value) => {
            Form.setState(state => {
              state.formRef.sheet_performance = (state.formRef.sheet_performance || state.formRef.performance);
              state.formRef.performance = value;
              return state;
            })

            matrizAliz = await getAlizInMatriz(Form.state.formRef);

            const matrizFech = await getFechaduraInMatriz(Form.state.formRef);
            matrizDoorLocks = {
              fechaduras: matrizFech['fechaduras'],
              ferragens: matrizFech['ferragens'],
            }

            const matrizPanel = await getPanelInMatriz(_.state.formRef);
            matrizPanels = {
              panel_6mm: matrizPanel['panel_6mm'],
              panel_15mm: matrizPanel['panel_15mm'],
              panel_18mm: matrizPanel['panel_18mm'],
              panel_steel_frame: matrizPanel['panel_steel_frame'],
            };

            Form.forceUpdate();
          }
        }
      },
      {
        type: 'select',
        key: 'sheet_performance',
        title: 'Desempenho da folha',
        options: [],
        optionConfig: { name: 'performance' },
        loaded: false,
        colSize: 3,
        'element-attr': {
          required: false,
          value: (v) => v.state.formRef.sheet_performance || v.state.formRef.performance,
          onChangeOverride: async (Form, _, value) => {
            const confirm = Modal.confirm;

            confirm({
              title: 'Atenção!',
              content: 'Tem Certeza que deseja alterar o desempenho? Esta alteração removerá todas as folhas associadas.',
              onOk() {
                Form.setState(state => {
                  state.formRef.sheet_performance = value;
                  state.formRef.comp_folha = [];
                  return state;
                })
              }
            })

            Form.forceUpdate();
          }
        }
      },
      {
        type: 'select',
        key: 'marco_code',
        title: 'Código Marco',
        options: [],
        optionConfig: { name: 'marco_code' },
        loaded: false,
        colSize: 3,
        'element-attr': {
          onChangeOverride: (Form, _, value) => {
            if (Form.state.formRef.marco_code) {
              const confirm = Modal.confirm;
              confirm({
                title: 'Atenção!',
                content: 'Tem Certeza que deseja alterar o código marco? Esta alteração removerá todos as marcos associados.',
                onOk() {
                  Form.setState(state => {
                    state.formRef.marco_code = value;
                    state.formRef.comp_marco = [];
                    state.formRef.comp_marco_passagem = [];
                    return state;
                  })
                }
              });
            } else {
              Form.setState(state => {
                state.formRef.marco_code = value;
                state.formRef.comp_marco = [];
                state.formRef.comp_marco_passagem = [];
                return state;
              })
            }
          }
        }
      },
      {
        type: 'switch',
        key: 'accessibility',
        title: 'Acessibilidade',
        colSize: 3,
        "element-attr": {
          required: true
        }
      }
    ],
    [
      {
        type: 'select',
        key: 'kit_environment',
        title: 'Ambiente',
        options: [],
        optionConfig: { name: 'kit_amb' },
        loaded: false,
        colSize: 6,
        "element-attr": { mode: 'multiple' }
      },
      {
        key: 'kit_performance_level',
        title: 'Nível de Desempenho',
        colSize: 4,
        type: 'render',
        render: (state) => {
          const performance_level = checklists.find(el => (el.get('performances') || []).includes(state.formRef['performance']));
          return (
            <Input
              placeholder='Nível de Desempenho'
              value={performance_level && performance_level.get('kit_performance')}
              size='large'
            />
          )
        },
      },
      {
        key: 'kit_traffic',
        title: 'Tráfego',
        colSize: 3,
        type: 'render',
        render: (state) => {
          const performance_level = checklists.find(el => (el.get('performances') || []).includes(state.formRef['performance']));
          return (
            <Input
              placeholder='Tráfego'
              value={performance_level && performance_level.get('kit_cicle')}
              size='large'
            />
          )
        },
      },
      {
        key: "vup",
        title: "Vida útil de projeto",
        colSize: 3,
        type: 'render',
        render: (state) => {
          const performance_level = checklists.find(el => (el.get('performances') || []).includes(state.formRef['performance']));
          return (
            <Input
              placeholder='Vida útil de projeto'
              value={performance_level && performance_level.get('kit_vup')}
              size='large'
            />
          )
        },
      },
      {
        type: 'switch',
        key: 'unavailable',
        title: 'Indisponível',
        colSize: 3,
        "element-attr": {
          required: false
        }
      }
    ],
    [
      {
        "title": "Ocupação",
        "key": "occupation",
        "type": "relation",
        "options": [],
        "relation-select": {
          "class": "Occupation",
          "label": "description",
          "value": "objectId",
          "key": "objectId",
          firstLoad: true
        },
        colSize: 6,
        "element-attr": { "required": false }
      },
      {
        type: 'render',
        key: 'height_marco',
        title: 'Altura do Marco',
        colSize: 3,
        render: (state, module, node) => {
          return (
            <Input
              onChange={event => module.updateRefValue(node, event.target.value, 'height_marco')}
              size="large"
              placeholder='Altura Marco'
              value={state.formRef['height_marco'] || state.formRef['height']}
            />
          )
        },
        beforeSave: (module, parseObject, node) => {
          parseObject.set('height_marco', module.state.formRef.height_marco)
          return true;
        }
      }, {
        type: 'select',
        key: 'height',
        title: 'Altura da Folha',
        options: [],
        optionConfig: { name: 'alturas_folha' },
        loaded: false,
        colSize: 3,
        "element-attr": {
          afterChange: async (_) => {
            matrizAliz = await getAlizInMatriz(_.state.formRef);
            _.forceUpdate();
          }
        }
      },
      {
        type: 'select',
        key: 'width',
        title: 'Larguras',
        options: [],
        optionConfig: { name: 'larguras_folha' },
        loaded: false,
        colSize: 5,
        "element-attr": {
          mode: 'multiple',
          afterChange: async (_) => {
            matrizAliz = await getAlizInMatriz(_.state.formRef);
            _.forceUpdate();
          }
        }
      },
      {
        type: 'number',
        key: 'sheet_qtd',
        title: 'Qtd de folhas',
        colSize: 2,
        step: 1,
        "element-attr": {
          min: 1, max: 4,
          afterChange: async (_) => {
            matrizAliz = await getAlizInMatriz(_.state.formRef);
            _.forceUpdate();
          }
        }
      }
    ],
    [
      { type: 'render', colSize: 24, key: 'divider-all', render: () => <Divider />, className: 'divider-all-row' }
    ],
    [
      {
        type: 'header',
        title: 'Folha',
        key: 'title_folha'
      },
      {
        "title": "Componentes Folha",
        "key": "comp_folha",
        "type": "relation",
        "options": [],
        "relation-select": {
          "class": "ComponenteKit",
          "label": row => `${row.product_code} - ${row.description_component}`,
          "orderBy": "product_code",
          "value": "objectId",
          "key": "objectId",
          searchFields: ['product_code', 'description_component'],
          where: {
            group: 'Folha',
            is_add: false,
            performance: el => el.props.form.state.formRef.sheet_performance || el.props.form.state.formRef.performance,
            height_component: el => Number(el.props.form.state.formRef.height)
          }
        },
        colSize: 24,
        "element-attr": { disabled: form => !((form.state.formRef.sheet_performance || form.state.formRef.performance) && form.state.formRef.marco_code) },
        filter: (Form, values) => {
          return (values || []).filter(v => {
            return v.get('performance') === (Form.state.formRef.sheet_performance || Form.state.formRef.performance)
          })
        }
      },
      {
        "title": "Componentes Folha Inativa (para kits assimétricos)",
        "key": "comp_folha_inativa",
        "type": "relation",
        "options": [],
        "relation-select": {
          "class": "ComponenteKit",
          "label": row => `${row.product_code} - ${row.description_component}`,
          "orderBy": "product_code",
          "value": "objectId",
          "key": "objectId",
          searchFields: ['product_code', 'description_component'],
          where: {
            group: 'Folha',
            is_add: false,
            performance: el => el.props.form.state.formRef.sheet_performance || el.props.form.state.formRef.performance,
            height_component: el => Number(el.props.form.state.formRef.height)
          }
        },
        colSize: 24,
        "element-attr": { disabled: form => !((form.state.formRef.sheet_performance || form.state.formRef.performance) && form.state.formRef.marco_code), required: false, },
        filter: (Form, values) => {
          return (values || []).filter(v => {
            return v.get('performance') === (Form.state.formRef.sheet_performance || Form.state.formRef.performance)
          })
        }
      },
      {
        type: 'switch',
        title: 'Nova lógica do acabamento (folha)',
        key: 'sheet_finishing_new_logic',
        "element-attr": {
          required: false,
        }
      }
    ],
    [
      {
        type: 'header',
        title: 'Marco',
        key: 'title_marco'
      },
      {
        "title": "Componentes Marco",
        "key": "comp_marco",
        "type": "relation",
        "options": [],
        "relation-select": {
          "class": "ComponenteKit",
          "label": row => `${row.product_code} - ${row.description_component}`,
          "orderBy": "product_code",
          "value": "objectId",
          "key": "objectId",
          searchFields: ['product_code', 'description_component'],
          where: {
            group: 'Marco',
            is_add: false,
            marco_code: el => el.props.form.state.formRef.marco_code,
            height_component: el => Number(el.props.form.state.formRef.height_marco || el.props.form.state.formRef.height)
          }
        },
        colSize: 24,
        "element-attr": { "required": false, disabled: form => !(form.state.formRef.performance && form.state.formRef.marco_code) },
        filter: (Form, values) => {
          return (values || []).filter(v => {
            return v.get('marco_code') === Form.state.formRef.marco_code
          })
        }
      },
      {
        "title": "Componentes Marco de Passagem",
        "key": "comp_marco_passagem",
        "type": "relation",
        "options": [],
        "relation-select": {
          "class": "ComponenteKit",
          "label": row => `${row.product_code} - ${row.description_component}`,
          "orderBy": "product_code",
          "value": "objectId",
          "key": "objectId",
          searchFields: ['product_code', 'description_component'],
          where: {
            group: 'Marco',
            is_add: false,
            height_component: el => Number(el.props.form.state.formRef.height_marco || el.props.form.state.formRef.height)
          }
        },
        colSize: 24,
        "element-attr": { "required": false, disabled: form => !form.state.formRef.performance },
      },
      {
        type: 'switch',
        title: 'Nova lógica do acabamento (marco)',
        key: 'marco_finishing_new_logic',
        "element-attr": {
          required: false,
        }
      }
    ],
    [
      {
        type: 'header',
        title: 'Montagem e Instalação',
        key: 'title_mounting'
      },
      {
        "title": "Montagem",
        "key": "comp_mounting",
        "type": "relation-search",
        "options": [],
        "relation-select": {
          "class": "MountingKit",
          "label": 'code',
          "value": "objectId",
          "key": "objectId",
          // searchFields: ['typology', 'ironmongery', 'weight'],
        },
        colSize: 12,
        "element-attr": { "required": true, mode: 'default' },
        filter: (Form, values) => {
          return (values || []).filter(v => {
            return (
              (Form.state.formRef.typology || []).includes(v.get('typology')) &&
              (v.get('aplication') || []).includes(Form.state.formRef.performance) &&
              v.get('height').toString() === Form.state.formRef.height.toString() //&&
              // (v.get('width') || []).every(v => Form.state.formRef.width.includes(v.toString()))
            )
          })
        }
      },
      {
        "title": "Instalação",
        "key": "instalation_kit",
        "type": "relation-search",
        "options": [],
        "relation-select": {
          "class": "InstalationKit",
          "label": 'code',
          "value": "objectId",
          "key": "objectId"
        },
        colSize: 12,
        "element-attr": { "required": true, "mode": 'default' },
        filter: (Form, values) => {
          return (values || []).filter(v => {
            return (
              (Form.state.formRef.typology || []).includes(v.get('typology')) &&
              (v.get('aplication') || []).includes(Form.state.formRef.performance) &&
              v.get('height').toString() === Form.state.formRef.height.toString() //&&
              // (v.get('width') || []).every(v => Form.state.formRef.width.includes(v.toString()))
            )
          })
        }
      },
    ],
    [
      { type: 'render', colSize: 24, key: 'divider-all-2', render: () => <Divider />, className: 'divider-all-row' }
    ],
    [
      {
        title: 'Arremates',
        type: 'header',
        key: 'alizHeader'
      },
    ],
    [
      {
        "title": "Alizar Standard",
        "key": "aliz",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizAliz['aliz']
            .filter(el => !el.get('description_component').toLowerCase().includes('fusion frame'))
            .sort((a, b) => a.get('product_code') > b.get('product_code') ? 1 : -1)
            .map(aliz => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={aliz._id}>
                  {aliz.get('product_code')} - {aliz.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        "title": "Alizar Fusion Frame",
        "key": "aliz_fusion_frame",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizAliz['aliz']
            .filter(el => el.get('description_component').toLowerCase().includes('fusion frame'))
            .sort((a, b) => a.get('product_code') > b.get('product_code') ? 1 : -1)
            .map(aliz => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={aliz._id}>
                  {aliz.get('product_code')} - {aliz.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        "title": "Bandeira falsa",
        "key": "aliz_with_band",
        "type": "render",
        "size": 24,
        "render": (state) => {
          return matrizAliz['alizWithBand']
            .filter(el => !el.get('description_component').toLowerCase().includes('fusion frame'))
            .sort((a, b) => a.get('product_code') > b.get('product_code') ? 1 : -1)
            .map(aliz => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={aliz._id}>
                  {aliz.get('product_code')} - {aliz.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        "title": "Bandeira Fusion frame",
        "key": "aliz_with_band_fusion_frame",
        "type": "render",
        "size": 24,
        "render": (state) => {
          return matrizAliz['alizWithBand']
            .filter(el => el.get('description_component').toLowerCase().includes('fusion frame'))
            .sort((a, b) => a.get('product_code') > b.get('product_code') ? 1 : -1)
            .map(aliz => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={aliz._id}>
                  {aliz.get('product_code')} - {aliz.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        "title": "Painel",
        "key": "aliz_with_panel",
        "type": "render",
        "size": 24,
        "render": (state) => {
          return matrizAliz['alizWithPanel']
            .filter(el => !el.get('description_component').toLowerCase().includes('fusion frame'))
            .sort((a, b) => a.get('product_code') > b.get('product_code') ? 1 : -1)
            .map(aliz => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={aliz._id}>
                  {aliz.get('product_code')} - {aliz.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        "title": "Painel Fusion frame",
        "key": "aliz_with_panel_fusion_frame",
        "type": "render",
        "size": 24,
        "render": (state) => {
          return matrizAliz['alizWithPanel']
            .filter(el => el.get('description_component').toLowerCase().includes('fusion frame'))
            .sort((a, b) => a.get('product_code') > b.get('product_code') ? 1 : -1)
            .map(aliz => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={aliz._id}>
                  {aliz.get('product_code')} - {aliz.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        title: 'Fechadura e Ferragens',
        type: 'header',
        key: 'ferragem'
      },
    ],
    [
      {
        "title": "Fechaduras",
        "key": "fer_fecha",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizDoorLocks['fechaduras']
            .map(fechadura => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={fechadura._id}>
                  {fechadura.get('product_code')} - {fechadura.get('description_component')}
                </Tag>
              );
            });
        },
      },
    ],
    [
      {
        "title": "Maçaneta",
        "key": "fer_mac",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizDoorLocks['ferragens']
            .filter(ferragem => ferragem.get('add_category') === 'Maçaneta')
            .map(ferragem => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={ferragem._id}>
                  {ferragem.get('product_code')} - {ferragem.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        "title": "Puxador",
        "key": "fer_pux",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizDoorLocks['ferragens']
            .filter(ferragem => ferragem.get('add_category') === 'Puxador')
            .map(ferragem => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={ferragem._id}>
                  {ferragem.get('product_code')} - {ferragem.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        "title": "Barra antipânico",
        "key": "fer_bar_ap",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizDoorLocks['ferragens']
            .filter(ferragem => ferragem.get('add_category') === 'Barra Antipânico')
            .map(ferragem => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={ferragem._id}>
                  {ferragem.get('product_code')} - {ferragem.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        "title": "Mola Aérea",
        "key": "fer_mol",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizDoorLocks['ferragens']
            .filter(ferragem => ferragem.get('add_category') === 'Mola Aérea')
            .map(ferragem => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={ferragem._id}>
                  {ferragem.get('product_code')} - {ferragem.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        "title": "Batedor de Porta",
        "key": "fer_bat",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizDoorLocks['ferragens']
            .filter(ferragem => ferragem.get('add_category') === 'Batedor')
            .map(ferragem => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={ferragem._id}>
                  {ferragem.get('product_code')} - {ferragem.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        "title": "Visor-Olho Mágico",
        "key": "fer_olh",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizDoorLocks['ferragens']
            .filter(ferragem => ferragem.get('add_category') === 'Olho mágico')
            .map(ferragem => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={ferragem._id}>
                  {ferragem.get('product_code')} - {ferragem.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        "title": "Fecho de Segurança",
        "key": "fer_fech_seg",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizDoorLocks['ferragens']
            .filter(ferragem => ferragem.get('add_category') === 'Fecho de segurança')
            .map(ferragem => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={ferragem._id}>
                  {ferragem.get('product_code')} - {ferragem.get('description_component')}
                </Tag>
              );
            })
        },
      },
    ],
    [
      {
        title: 'Painéis',
        type: 'header',
        key: 'panel'
      },
    ],
    [
      {
        "title": "Paineis 6mm",
        "key": "panel_6mms",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizPanels['panel_6mm']
            .map(panel => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={panel._id}>
                  {panel.get('product_code')} - {panel.get('description_component')}
                </Tag>
              );
            });
        },
      },
    ],
    [
      {
        "title": "Paineis 15mm",
        "key": "panel_15mms",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizPanels['panel_15mm']
            .map(panel => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={panel._id}>
                  {panel.get('product_code')} - {panel.get('description_component')}
                </Tag>
              );
            });
        },
      },
    ],
    [
      {
        "title": "Paineis 3D 18mm",
        "key": "panel_18mms",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizPanels['panel_18mm']
            .map(panel => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={panel._id}>
                  {panel.get('product_code')} - {panel.get('description_component')}
                </Tag>
              );
            });
        },
      },
    ],
    [
      {
        "title": "Paineis Steel Frame",
        "key": "panel_steel_frame",
        "type": "render",
        "size": 24,
        "render": () => {
          return matrizPanels['panel_steel_frame']
            .map(panel => {
              return (
                <Tag style={{ marginBottom: 10, fontSize: 14 }} key={panel._id}>
                  {panel.get('product_code')} - {panel.get('description_component')}
                </Tag>
              );
            });
        },
      },
    ],
  ],
  submit: {
    collection: "Kit"
  },
  className: 'KitModule',
  schema: [
    {
      title: 'Código',
      key: 'code',
      dataIndex: 'code',
      align: 'center',
      sorter: (a, b) => a.code.localeCompare(b.code),
      type: 'text'
    }, {
      title: 'Descrição do kit',
      key: 'name',
      dataIndex: 'name',
      align: 'center',
      sorter: (a, b) => a.name.localeCompare(b.name),
      type: 'text'
    }, {
      title: 'Tipologia',
      key: 'typology',
      dataIndex: 'typology',
      align: 'center',
      render: v => {
        return (v || [])
          .map((usage, i) => {
            const typology = typologies.find(el => el.value === usage)
            return (
              <Tag key={i}>
                {typology && typology.label}
              </Tag>
            )
          })
      }
    }, {
      title: "FT Disponível",
      key: "unavailable",
      dataIndex: 'unavailable',
      align: 'center',
      width: '50px',
      type: 'render',
      render: (value) => {
        return (
          <div
            style={{
              backgroundColor: value ? 'red' : 'green',
              width: '20px', height: '20px',
              margin: 'auto', borderRadius: '50%'
            }}
          />
        );
      }
    },
  ],
  "title-module": "Ficha Técnica de Kits",
  "title-navigation": <div><Icon type="tool" /> Ficha Técnica de Kits</div>,
  "router-base": "/panel/kit",
  "module": "Kit",
  'topBarAction': ({ module }) =>
    <div style={{ float: 'right' }}>
      {/* <Button onClick={() => changeToArray()}>Test</Button> */}
      <MassChangeModal />
    </div>,
}

export default KitModule