import React from "react";
import { Icon } from "antd";

export default {
  fields: [
    [
      {
        type: "text",
        key: "type",
        title: "Nome do pacote (identificador)",
        colSize: 14,
        "element-attr": {
          required: true,
        },
      },
      {
        type: "number",
        key: "gold_aditional_percent",
        title: "Adicional pacote avançado (%)",
        colSize: 5,
        "element-attr": { required: true },
      },
      {
        type: "number",
        key: "platinum_aditional_percent",
        title: "Adicional pacote completo (%)",
        colSize: 5,
        "element-attr": { required: true },
      },
    ],
    [
      {
				title: "Instalação",
				key: "instalation",
				type: "pointer",
				options: [],
				"relation-select": {
					"class": "Instalation",
					"label": "name",
					"value": "objectId",
					"key": "objectId"
				},
				colSize: 6,
				"element-attr": { required: true, "mode": 'default' }
			},
      {
				title: "Tipo de obra",
				key: "type_work",
				type: "pointer",
				options: [],
				"relation-select": {
					"class": "TypeWork",
					"label": "name",
					"value": "objectId",
					"key": "objectId"
				},
				colSize: 6,
				"element-attr": { required: true, "mode": 'default' }
			},
      {
        type: "number",
        key: "instalation_aditional_percent",
        title: "Adicional Instalação",
        colSize: 6,
        "element-attr": { required: true },
      },
      {
        type: "number",
        key: "max_total",
        title: "Total máximo",
        colSize: 6,
        "element-attr": { required: true },
      },
    ],
    [
      {
        title: "Quadro",
        key: "architectural_service_table",
        type: "dynamic-input-list",
        fields: [
          [
            {
              title: "Pacote de serviços",
              key: "services_plan",
              type: "text",
              colSize: 12,
              "element-attr": { required: false },
            },
            {
              title: "Básico",
              key: "basic",
              type: "text",
              colSize: 4,
              "element-attr": { required: false },
            },
            {
              title: "Consultoria Técnica",
              key: "gold",
              type: "text",
              colSize: 4,
              "element-attr": { required: false },
            },
            {
              title: "Asssessoria Técnica",
              key: "platinum",
              type: "text",
              colSize: 4,
              "element-attr": { required: false },
            },
          ],
        ],
        "element-attr": { required: false },
      },
    ],
  ],
  submit: {
    collection: "ArchitecturalServices",
  },
  className: "config-component",
  schema: [
    {
      title: "Nome do pacote (identificador)",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Adicional pacote avançado (%)",
      key: "gold_aditional_percent",
      dataIndex: "gold_aditional_percent",
      render: (value) => value && `${value}%`,
    },
    {
      title: "Adicional pacote completo (%)",
      key: "platinum_aditional_percent",
      dataIndex: "platinum_aditional_percent",
      render: (value) => value && `${value}%`,
    },
  ],
  "title-navigation": (
    <div>
      <Icon type="form" /> Pacotes de Serviços
    </div>
  ),
  "title-module": "Pacotes de Serviços",
  module: "ServicesPackage",
  "router-base": "/panel/services-package",
};
