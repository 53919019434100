import React from "react"
import { roundTo } from "../../../../utils/mathFunctions"
import { findAll, findById, findWhere } from "../../../../utils/db_functions"
import { calculateTotal, getComponentTotalPrice } from "../../../components-and-kits/shared/component-mounting-instalation-functions"

const getProductPrice = async (productObject, inputs, formulas, salesChannelId = null, kit, components = null) => {

    let total = 0

    const kitChoosed = kit || (productObject['product_code'] ? await findById('Kit', productObject['product_code'], ['comp_mounting', 'instalation_kit']) : null)
    // Get sheet price
    if (productObject['finishing_sheet']) total += (await getComponentTotalPrice(productObject['finishing_sheet'], inputs, formulas, await getComponentKit(components, productObject['finishing_sheet'], productObject['sheet_option_finishing'])) * ((kitChoosed && kitChoosed.get('sheet_qtd')) || 1))
    if (productObject['finishing_sheet_inactive']) total += await getComponentTotalPrice(productObject['finishing_sheet_inactive'], inputs, formulas, await getComponentKit(components, productObject['finishing_sheet_inactive']))

    // Get marco price
    if (productObject['finishing_marco']) total += await getComponentTotalPrice(productObject['finishing_marco'], inputs, formulas, await getComponentKit(components, productObject['finishing_marco'], productObject['marco_option_finishing']))
    if (productObject['finishing_marco_passagem']) total += await getComponentTotalPrice(productObject['finishing_marco_passagem'], inputs, formulas, await getComponentKit(components, productObject['finishing_marco_passagem'], productObject['marco_passagem_option_finishing']))

    // Get aliz price
    if (productObject['finishing_aliz']) total += await getComponentTotalPrice(productObject['finishing_aliz'], inputs, formulas, await getComponentKit(components, productObject['finishing_aliz'], productObject['fixed_aliz_option_finishing']))
    if (productObject['finishing_aliz_adjustable']) total += await getComponentTotalPrice(productObject['finishing_aliz_adjustable'], inputs, formulas, await getComponentKit(components, productObject['finishing_aliz_adjustable'], productObject['adjustable_aliz_option_finishing']))

    // Get fechadura price
    if (productObject['conj_fechadura']) total += (await getComponentTotalPrice(productObject['conj_fechadura'], inputs, formulas, await getComponentKit(components, productObject['conj_fechadura'])) * productObject['lock_quantity'])
    if (productObject['hardware_door_lock']) total += (await getComponentTotalPrice(productObject['hardware_door_lock'], inputs, formulas, await getComponentKit(components, productObject['hardware_door_lock'])) * productObject['lock_quantity'])

    // Get ferragem price
    if (productObject['hardware_puller']) total += (await getComponentTotalPrice(productObject['hardware_puller'], inputs, formulas, await getComponentKit(components, productObject['hardware_puller'])) * productObject['hardware_puller_amount'])
    if (productObject['hardware_door_handle']) total += (await getComponentTotalPrice(productObject['hardware_door_handle'], inputs, formulas, await getComponentKit(components, productObject['hardware_door_handle'])) * productObject['lock_quantity'])
    if (productObject['hardware_bar_anti_panic']) total += (await getComponentTotalPrice(productObject['hardware_bar_anti_panic'], inputs, formulas, await getComponentKit(components, productObject['hardware_bar_anti_panic'])) * productObject['hardware_bar_anti_panic_amount'])
    if (productObject['hardware_aerial_spring']) total += (await getComponentTotalPrice(productObject['hardware_aerial_spring'], inputs, formulas, await getComponentKit(components, productObject['hardware_aerial_spring'])) * productObject['hardware_aerial_spring_amount'])
    if (productObject['hardware_accessories']) total += (await getComponentTotalPrice(productObject['hardware_accessories'], inputs, formulas, await getComponentKit(components, productObject['hardware_accessories'])) * productObject['hardware_accessories_amount'])
    if (productObject['hardware_accessories_02']) total += (await getComponentTotalPrice(productObject['hardware_accessories_02'], inputs, formulas, await getComponentKit(components, productObject['hardware_accessories_02'])) * productObject['hardware_accessories_amount_02'])
    if (productObject['hardware_accessories_03']) total += (await getComponentTotalPrice(productObject['hardware_accessories_03'], inputs, formulas, await getComponentKit(components, productObject['hardware_accessories_03'])) * productObject['hardware_accessories_amount_03'])

    // Get accessory price
    if (productObject['accessory']) total += (await getComponentTotalPrice(productObject['accessory'], inputs, formulas, await getComponentKit(components, productObject['accessory'])) * productObject['accessory_amount'])

    if (productObject['product_code'] && kitChoosed) {
        // Get mounting and instalation
        let mountingParseObject = kitChoosed.get('comp_mounting')
        if (mountingParseObject) {
            mountingParseObject.set('ref_unity_value_hh', mountingParseObject.get('unity_value_hh').get('calculatedTotal'))
            const mounting = mountingParseObject.toJSON()
            total += await calculateTotal(mounting, false, inputs, formulas)
        }

        if (productObject['instalation']) {
            const instalationChoosed = await findById('Instalation', productObject['instalation'])
            if (instalationChoosed && instalationChoosed.get('name').includes('Multidoor Service')) {
                let instalationParseObject = kitChoosed.get('instalation_kit')
                instalationParseObject.set('ref_unity_value_hh', instalationParseObject.get('unity_value_hh').get('calculatedTotal'))
                const instalation = instalationParseObject.toJSON()
                total += await calculateTotal(instalation, false, inputs, formulas)
            }
        }
    }

    for (let wallPanelIndex = 0; wallPanelIndex < productObject['wall_panel'].length; wallPanelIndex++) {
        const wallPanel = productObject['wall_panel'][wallPanelIndex]
        if (wallPanel['panel_wall_module']) total += (await getComponentTotalPrice(wallPanel['panel_wall_module'], inputs, formulas, await getComponentKit(components, wallPanel['panel_wall_module'])) * wallPanel['wall_quantity'])
    }

    const salesChannel = salesChannelId ? await findById('SalesChannel', salesChannelId) : null
    if (salesChannel) total = total * (1 + (salesChannel.get('value') / 100))

    return roundTo(total, 2)
}

const getComponentVolume = async (componentId, componentsKits = null) => {
    const component = componentsKits ? await getComponentKit(componentsKits, componentId) : await findById('ComponenteKit', componentId)
    const componentVolume = parseFloat((component ? component.get('volume') : 0).toFixed(2))
    return componentVolume
}

const getProductVolume = async (productObject, amount, kit = null, componentsKits = null) => {

    let total = 0

    const kitChoosed = kit || (productObject['product_code'] ? await findById('Kit', productObject['product_code']/*, ['comp_mounting', 'instalation_kit']*/) : null)

    // Get sheet volume
    if (productObject['finishing_sheet']) total += (await getComponentVolume(productObject['finishing_sheet'], componentsKits) * ((kitChoosed && kitChoosed.get('sheet_qtd')) || 1))
    if (productObject['finishing_sheet_inactive']) total += await getComponentVolume(productObject['finishing_sheet_inactive'], componentsKits)

    // Get marco volume
    if (productObject['finishing_marco']) total += await getComponentVolume(productObject['finishing_marco'], componentsKits)
    if (productObject['finishing_marco_passagem']) total += await getComponentVolume(productObject['finishing_marco_passagem'], componentsKits)

    // Get aliz volume
    if (productObject['finishing_aliz']) total += await getComponentVolume(productObject['finishing_aliz'], componentsKits)
    if (productObject['finishing_aliz_adjustable']) total += await getComponentVolume(productObject['finishing_aliz_adjustable'], componentsKits)

    return total * amount
}

const getComponentWeight = async (componentId, componentsKits = null) => {
    const component = componentsKits ? await getComponentKit(componentsKits, componentId) : await findById('ComponenteKit', componentId)
    const componentWeight = parseFloat((component ? component.get('teoric_weight') : 0).toFixed(2))
    return componentWeight
}

const getProductWeight = async (productObject, amount, kit = null, componentsKits = null) => {

    let total = 0

    const kitChoosed = kit || (productObject['product_code'] ? await findById('Kit', productObject['product_code']/*, ['comp_mounting', 'instalation_kit']*/) : null)

    // Get sheet volume
    if (productObject['finishing_sheet']) total += (await getComponentWeight(productObject['finishing_sheet'], componentsKits) * ((kitChoosed && kitChoosed.get('sheet_qtd')) || 1))
    if (productObject['finishing_sheet_inactive']) total += await getComponentWeight(productObject['finishing_sheet_inactive'], componentsKits)

    // Get marco volume
    if (productObject['finishing_marco']) total += await getComponentWeight(productObject['finishing_marco'], componentsKits)
    if (productObject['finishing_marco_passagem']) total += await getComponentWeight(productObject['finishing_marco_passagem'], componentsKits)

    // Get aliz volume
    if (productObject['finishing_aliz']) total += await getComponentWeight(productObject['finishing_aliz'], componentsKits)
    if (productObject['finishing_aliz_adjustable']) total += await getComponentWeight(productObject['finishing_aliz_adjustable'], componentsKits)

    return total * amount
}

const getNameOfProposalPdf = (proposalJson) => {

    let lastUpdatedDate = new Date(proposalJson.updatedAt)

    let changeNameDate = new Date(2020, 8, 19)

    let pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${(proposalJson.name_client || '').replace('/', '')}-${proposalJson.cod_cliente}-${proposalJson.proposal_code}.pdf`.replace(/\s/g, '-')

    if (lastUpdatedDate > changeNameDate) pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_proposal/${(proposalJson.name_client || '').replace('/', '')}-${proposalJson.proposal_code}-Multidoor.pdf`.replace(/\s/g, '-')

    return pdfName
}

const getNameOfAnaliticProposalPdf = (proposalJson) => {

    let lastUpdatedDate = new Date(proposalJson.updatedAt)

    let changeNameDate = new Date(2020, 8, 19)

    let pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_analitic_proposal/${(proposalJson.name_client || '').replace('/', '')}-${proposalJson.cod_cliente}-${proposalJson.proposal_code}.pdf`.replace(/\s/g, '-')

    if (lastUpdatedDate > changeNameDate) pdfName = `${process.env.REACT_APP_PUBLIC}/pdf_analitic_proposal/${(proposalJson.name_client || '').replace('/', '')}-${proposalJson.proposal_code}-Multidoor.pdf`.replace(/\s/g, '-')

    return pdfName
}

const calculateInstalationManagement = (amount) => {
    let instalation_management = null
    let instalation_management_time = null
    let instalation_management_percentual = null

    if (amount <= 300) {
        instalation_management = '4-3'
        instalation_management_time = 4
        instalation_management_percentual = 15
    } else if (amount <= 600) {
        instalation_management = '6-3'
        instalation_management_time = 6
        instalation_management_percentual = 15
    } else if (amount <= 1000) {
        instalation_management = '8-3'
        instalation_management_time = 8
        instalation_management_percentual = 15
    } else if (amount <= 2000) {
        instalation_management = '12-3'
        instalation_management_time = 12
        instalation_management_percentual = 20
    } else {
        instalation_management = '20-3'
        instalation_management_time = 20
        instalation_management_percentual = 25
    }
    return { instalation_management, instalation_management_time, instalation_management_percentual }
}

const calculateTotals = async (productBlocks, inputs, formulas, salesChannelId = null, isMultipleCotation = false) => {

    let totalPrice = 0
    let totalVolume = 0
    let totalWeight = 0
    let totalQtd = 0
    let unitValues = []

    for (let blockIndex = 0; blockIndex < productBlocks.length; blockIndex++) {
        const block = productBlocks[blockIndex];
        if (unitValues.length < blockIndex + 1) unitValues.push([])

        const kits = await findWhere('Kit', 'objectId', block['products'].map(el => el['product_code']), ['comp_mounting', 'instalation_kit'])
        const componentsKits = await findWhere(
            'ComponenteKit',
            'objectId',
            [
                ...new Set(
                    block['products'].map(el => {
                        return [
                            el['finishing_sheet'], el['finishing_sheet_inactive'], el['finishing_marco'], el['finishing_marco_passagem'], el['finishing_aliz'], el['finishing_aliz_adjustable'],
                            el['conj_fechadura'], el['hardware_door_lock'], el['hardware_puller'], el['hardware_door_handle'], el['hardware_bar_anti_panic'],
                            el['hardware_aerial_spring'], el['hardware_accessories'], el['hardware_accessories_02'], el['hardware_accessories_03'], el['finishing_aliz_adjustable'],
                            el['accessory'],
                        ].filter(el => el)
                    }).flat()
                )
            ],
            ['unity_value_hh'], null, null, null, 200
        )

        if (
            !isMultipleCotation ||
            (isMultipleCotation && (blockIndex === (productBlocks.length - 1)))
        ) {
            for (let productIndex = 0; productIndex < block['products'].length; productIndex++) {
                const product = block['products'][productIndex]

                const kit = product['product_code'] && kits[productIndex] ? kits[productIndex] : null
                const unitPrice = await getProductPrice(product, inputs, formulas, salesChannelId, kit, componentsKits)
                const unitAmount = (product.amount || 0)

                // totalPrice += (unitPrice * (product.amount || 0))
                totalVolume += (await getProductVolume(product, product.amount, kit, componentsKits))
                totalWeight += (await getProductWeight(product, product.amount, kit, componentsKits))
                totalQtd += unitAmount

                unitValues[blockIndex].push({ value: unitPrice, amount: unitAmount })
                totalPrice += (unitPrice * unitAmount)
            }
        }
    }

    return { totalPrice, totalVolume, totalWeight, totalQtd, unitValues }
}

const getPatternOfFinishingsComponent = (componentId, componentsKit, finishings) => {
    return ((
        (componentId && componentsKit.length > 0) ?
            finishings.find(finishing => {
                return finishing.finishingId === (
                    componentsKit.find(component => component.id === componentId) &&
                    componentsKit.find(component => component.id === componentId).get('finishing').id
                )
            }) || { patterns: [] }
            : { patterns: [] }
    )['patterns'])
}

const getPatternOfAFinishing = (finishingId, finishings) => {
    return ((
        (finishings || []).find(finishing => finishing.finishingId === finishingId)
    ) || { patterns: [] }
    )['patterns']
}

const filterComponentBySheetFinishing = (
    componentsKit,
    sheetId,
    componentsToFilter,
    finishings,
    matrizFinishing,
    finishingSheet = null,
) => {
    const sheetFinishing = finishingSheet || finishings.find(finishing => {
        return finishing.finishingId === (
            componentsKit.find(component => component.id === sheetId) &&
            componentsKit.find(component => component.id === sheetId).get('finishing').id
        )
    })
    if (!sheetFinishing) return []

    const finishingToFilter = matrizFinishing.find(el => {
        return el.get('finishing_sheet') && (el.get('finishing_sheet').id === sheetFinishing.finishingId)
    })
    if (!finishingToFilter) return componentsToFilter

    return (componentsToFilter || []).filter(el => {
        return el ? (finishingToFilter.get('finishing_marco_alizar') || []).includes(el.get("finishing").id) : false
    })
}

const isACompatibleSheetFinishing = (finishing, matrizFinishing, sheetFinishingId) => {

    const finishingToFilter = matrizFinishing.find(el => {
        return el.get('finishing_sheet') && (el.get('finishing_sheet').id === sheetFinishingId)
    })
    if (!finishingToFilter) return true

    return (finishingToFilter.get('finishing_marco_alizar') || []).includes(finishing)
}

const getComponentKit = async (components, id, finishingId = null) => {
    let component = (components || []).find(el => el.id === id) || null;

    if (component && finishingId) {
        const finishingInputGroup = (component.get("finishing_options_inputs") || []).find(el => el.finishing === finishingId)
        if (finishingInputGroup)
            await component.set("descriptions", [...component.get("descriptions"), { description: "Acabamento", rows: finishingInputGroup.rows }])
    }

    return component;
}

const transformClientContactsFieldsInArray = (proposal) => {
    return [
        { contact: 'Proposta', cargo: proposal.cargo_prop, phone: proposal.phone_prop, cel: proposal.phone_cel_prop, whatsapp: proposal.phone_whatsapp_prop, email: proposal.email_prop },
        { contact: 'Suprimentos', cargo: proposal.cargo_supri, phone: proposal.phone_supri, cel: proposal.phone_cel_supri, whatsapp: proposal.phone_whatsapp_supri, email: proposal.email_supri },
        { contact: 'Engenharia', cargo: proposal.cargo_eng, phone: proposal.phone_eng, cel: proposal.phone_cel_eng, whatsapp: proposal.phone_whatsapp_eng, email: proposal.email_eng },
        { contact: 'Gerência', cargo: proposal.cargo_management, phone: proposal.phone_management, cel: proposal.phone_cel_management, whatsapp: proposal.phone_whatsapp_management, email: proposal.email_management },
    ]
}

const transformObraContactsFieldsInArray = (proposal) => {
    return [
        { contact: 'Compras', cargo: proposal.cargo_obra_compras, phone: proposal.phone_obra_compras, cel: proposal.phone_cel_obra_compras, whatsapp: proposal.phone_whatsapp_obra_compras, email: proposal.email_obra_compras },
        { contact: 'Engenharia', cargo: proposal.cargo_obra_eng, phone: proposal.phone_obra_eng, cel: proposal.phone_cel_obra_eng, whatsapp: proposal.phone_whatsapp_obra_eng, email: proposal.email_obra_eng },
        { contact: 'Gerência', cargo: proposal.cargo_obra_management, phone: proposal.phone_obra_management, cel: proposal.phone_cel_obra_management, whatsapp: proposal.phone_whatsapp_obra_management, email: proposal.email_obra_management },
    ]
}
const transformProjectContactsFieldsInArray = (proposal) => {
    return [
        { contact: 'Arquitetura', cargo: proposal.cargo_project_arq, phone: proposal.phone_project_arq, cel: proposal.phone_cel_project_arq, whatsapp: proposal.phone_whatsapp_project_arq, email: proposal.email_project_arq },
        { contact: 'Especificação', cargo: proposal.cargo_project_espec, phone: proposal.phone_project_espec, cel: proposal.phone_cel_project_espec, whatsapp: proposal.phone_whatsapp_project_espec, email: proposal.email_project_espec },
        { contact: 'Compatibilização', cargo: proposal.cargo_project_compat, phone: proposal.phone_project_compat, cel: proposal.phone_cel_project_compat, whatsapp: proposal.phone_whatsapp_project_compat, email: proposal.email_project_compat },
    ]
}

const clearProductData = (productData) => {
    return {
        ...productData,
        finishing_sheet: null,
        pattern_sheet: null,
        thickness_marco: null,
        finishing_marco: null,
        pattern_marco: null,
        finishing_marco_passagem: null,
        pattern_marco_passagem: null,
        marco_add_flag_panel: null,
        marco_add_flag_panel_glass: null,
        marco_add_passage: null,
        marco_add_seteira: null,
        aliz_options: null,
        aliz_options_01: null,
        aliz_options_02: null,
        model_aliz: null,
        finishing_aliz: null,
        pattern_aliz: null,
        finishing_aliz_adjustable: null,
        pattern_aliz_adjustable: null,
        fixed_aliz: null,
        adjustable_aliz: null,
        option_fechadura: null,
        lock_door_lock: null,
        conj_fechadura: null,
        hardware_door_lock: null,
        lock_door_handle: null,
        lock_quantity: null,
        hardware_puller: null,
        hardware_bar_anti_panic: null,
        hardware_aerial_spring: null,
        hardware_accessories: null,
        hardware_accessories_02: null,
        hardware_accessories_03: null,
        hardware_quantity: null,
        hardware_door_handle: null,
        panel_wall_type: null,
        panel_wall_dimensions: null,
        panel_wall_module: null,
        panel_wall_finishing: null,
        panel_wall_pattern: null,
        fixing_substrate: null,
        wall_quantity: null,
    }
}

const renderProductNotes = (productBlocks, checklistConfigs) => {
    const performancesAndTypologies = [
        ...new Set(
            productBlocks.map(block => {
                return block["products"].map(product => {
                    return `${product.profile_performance}__${product.typology}`
                })
            }).flat()
        )
    ]

    return performancesAndTypologies.map(el => {
        const performance = el.split("__")[0].trim()
        const typology = el.split("__")[1].trim()

        const checklistConfig = checklistConfigs.find(checklist => (checklist.get("performances") || []).includes(performance))
        const note = ((checklistConfig && checklistConfig.get("proposal_notes")) || []).find(note => (note.typology || []).map(el => el.trim()).includes(typology))

        if (!note) return null

        return (
            <div>
                <div className='product-note-title'>{performance} {typology}</div>
                <div className='product-note'>{note.notes}</div>
            </div>
        )
    })
}

const getSalesChannelPaymentRule = (salesChannels, salesChannelToGet, ruleToGet) => {
    const saleChannelFound = (salesChannels || []).find(salesChannel => salesChannel.description === salesChannelToGet)

    if (!saleChannelFound || !saleChannelFound.paymentRules) return null

    const ruleFound = saleChannelFound.paymentRules.find(rule => rule.rule === ruleToGet)

    return ruleFound
}

const setAlizFinishingEqualMarco = ({
    alizMatriz,
    marcoComponents,
    marcoId,
    finishingId = null,
    sections = { fixed: '', adjustable: '' },
    panelFlag,
    updateFunction,
    newMethodology = null,
    adjustableType = null,
    alizType = null
}) => {

    if (!finishingId) {
        const marcoComponent = (marcoComponents || []).find(marco => marco.id === marcoId)
        if (!marcoComponent) return;
        finishingId = marcoComponent.get('finishing').id
    }

    const alizares = (alizMatriz || [])
        .map(matriz => matriz.get(alizType || 'component_kit'))
        .flat()
        .filter(el => el)
        .sort((a, b) => (a.get('description_component') > b.get('description_component')) ? 1 : ((b.get('description_component') > a.get('description_component')) ? -1 : 0))
        .filter(el => el.get('finishing'))

    const alizarF = alizares.find(el => {
        if (newMethodology) {
            const finishingIds = (el.get('finishing_options_inputs') || []).map(el => el && el.finishing).filter(el => el)
            return (
                finishingIds.includes(finishingId) && el.get('description_component').toLowerCase().includes('fix') &&
                (sections.fixed.slice(1, -1) === (el.get('fill_componente') || '').toString())
            )
        } else {
            return (
                el.get('finishing').id === finishingId && el.get('description_component').toLowerCase().includes('fix') &&
                (sections.fixed.slice(1, -1) === (el.get('fill_componente') || '').toString())
            )
        }
    })

    const alizarR = alizares.find(el => {
        if (newMethodology) {
            const finishingIds = (el.get('finishing_options_inputs') || []).map(el => el && el.finishing).filter(el => el)
            return (
                finishingIds.includes(finishingId) && el.get('description_component').toLowerCase().includes('regul') &&
                (sections.adjustable.slice(1, -1) === (el.get('fill_componente') || '').toString()) &&
                (el.get('description_component').includes(adjustableType))
            )
        } else {
            return (
                el.get('finishing').id === finishingId && el.get('description_component').toLowerCase().includes('regul') &&
                (sections.adjustable.slice(1, -1) === (el.get('fill_componente') || '').toString()) &&
                (el.get('description_component').includes(adjustableType))
            )
        }
    })

    if (newMethodology) updateFunction({ fixed: alizarF && `${alizarF.id}__${finishingId}`, adjustable: alizarR && `${alizarR.id}__${finishingId}` })
    else updateFunction({ fixed: alizarF && alizarF.id, adjustable: alizarR && alizarR.id })
}

const setPanelFinishingEqualSheet = ({
    panelsMatriz,
    wallPanelType,
    wallPanelDimensions,
    finishings,
    finishingId = null,
    patternId = null,
    sheetComponents,
    sheetId,
    updateFunction
}) => {

    if (!finishingId) {
        const sheetComponent = (sheetComponents || []).find(sheet => sheet.id === sheetId)
        if (!sheetComponent) return;
        finishingId = sheetComponent.get('finishing').id
    }

    const panels = (panelsMatriz || [])
        .map(matriz => matriz.get(wallPanelType))
        .flat()
        .filter(el => {
            const [widthComponent, heightComponent, isFusionFrame] = (wallPanelDimensions || 'x').split('x')
            return (
                el &&
                el.get('width_component') === parseInt(widthComponent || 0) &&
                el.get('height_component') === parseInt(heightComponent || 0) &&
                (isFusionFrame === 'fusionframe' ? el.get('description_component').toLowerCase().includes('fusion frame') : true)
            )
        })
        .map(el => {
            const finishing = finishings.find(finishing => finishing.finishingId === el.get('finishing').id) || null
            return {
                ...finishing,
                module: el.id
            }
        })
        .filter(el => el)

    const panel = panels.find(el => el.finishingId === finishingId)

    updateFunction({
        finishing: panel && panel.module,
        pattern: panel && panel.module ? patternId : undefined,
    })
}

const getMarcoPassagem = (compMarcoPassagem, heightMarco, thickness) => {
    const marcoPassagem = compMarcoPassagem
        .find(el => {
            return (
                (el.get('height_component') || '').toString() === (heightMarco || '').toString() &&
                (el.get('fill_componente') || '').toString() === (thickness || '').toString()
            )
        })

    return marcoPassagem && marcoPassagem.id
}

const getProposalDates = async (proposal) => {
    let pdfDates = (proposal.get('generalInfo') && proposal.get('generalInfo')['pdfDates']) || {}

    const proposalCode = proposal.get('proposal_code')
    const proposalVersion = proposalCode.split('-')[2]
    const proposalCodeWithoutVersion = proposalCode.substring(0, proposalCode.length - 2);

    const emissionDate = new Date()
    const revisionDate = proposal.get('updatedAt')

    let firstEmissionDate = pdfDates['firstEmissionDate'];
    if (proposalVersion === '01') {
        firstEmissionDate = new Date()
    } else {
        if (!firstEmissionDate) {
            const firstProposalVersion = await findWhere('Proposal', 'proposal_code', [`${proposalCodeWithoutVersion}01`])
            firstEmissionDate = firstProposalVersion[0].get('updatedAt');
        }
    }

    return { emissionDate, revisionDate, firstEmissionDate }
}

const getServicePackage = async (instalation, typeWork) => {
    const servicesPackages = await findAll('ArchitecturalServices', 1000, ['instalation', 'type_work']);
    return (servicesPackages || [])
        .filter(servicePackage => servicePackage.get('instalation') && servicePackage.get('type_work'))
        .find(servicePackage => servicePackage.get('instalation').id === instalation && servicePackage.get('type_work').id === typeWork)
}

export {
    getProductPrice,
    getNameOfProposalPdf,
    getNameOfAnaliticProposalPdf,
    getProductVolume,
    getProductWeight,
    calculateInstalationManagement,
    calculateTotals,
    getPatternOfFinishingsComponent,
    getPatternOfAFinishing,
    filterComponentBySheetFinishing,
    isACompatibleSheetFinishing,
    transformClientContactsFieldsInArray,
    transformObraContactsFieldsInArray,
    transformProjectContactsFieldsInArray,
    clearProductData,
    renderProductNotes,
    getSalesChannelPaymentRule,
    getComponentKit,
    setAlizFinishingEqualMarco,
    setPanelFinishingEqualSheet,
    getMarcoPassagem,
    getProposalDates,
    getServicePackage,
}