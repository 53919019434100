import React, { Component, Fragment } from 'react'
import { Col, DatePicker, Divider, Form, Input, Row, Select, Spin } from 'antd'
import pt_BR from 'antd/es/locale-provider/pt_BR';
import { searchAddress } from '../../../../../utils/general_functions'
import { findAll, findWhere } from '../../../../../utils/db_functions'
import { CreateMask, CreatePhoneMask, ValidateEmail } from '../../../../../utils/mask'
import moment from 'moment'
import ContactsFields from '../contacts-fields';

export default class WorkStep extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            dataConfigs: {},
            workStageData: [],
            previsionBuyData: [],
            instalationData: [],
            providerData: [],
            teamMobData: [],
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true })

        const dataConfigsArray = await findWhere('Config', 'name', [
            'usage', 'delivery', 'proposal_form_notes_obra',
        ])
        const dataConfigs = dataConfigsArray.reduce((acc, value) => {
            acc[value.get('name')] = value.get('value')
            return acc;
        }, {})

        const workStageData = this.props.dataConfigs['work-workStageData'] || await findAll('WorkStage')
        const previsionBuyData = this.props.dataConfigs['work-previsionBuyData'] || await findAll('PrevisionBuy')
        const instalationData = this.props.dataConfigs['project-instalationData'] || await findAll('Instalation')
        const providerData = this.props.dataConfigs['project-providerData'] || await findAll('Provider')
        const teamMobData = this.props.dataConfigs['instalation-teamMobData'] || await findAll('TeamMob')

        this.setState({ loading: false, dataConfigs, workStageData, previsionBuyData, instalationData, providerData, teamMobData })

        this.props.setDataConfigs({
            'work-workStageData': workStageData, 'work-previsionBuyData': previsionBuyData,
            'project-instalationData': instalationData, 'project-providerData': providerData,
            'instalation-teamMobData': teamMobData,
        })
    }

    verifyInstalationIsAdded(id) {
        if (id) {
            let instalation = (this.state.instalationData || []).find(val => val.id === id)
            this.props.updateDataValue('abridge_installation', (instalation && instalation.get('abridge_installation')))
        }
    }

    render() {
        return (
            <div>
                <Spin spinning={this.state.loading} tip='Carregando as informações...' size='large'>
                    <Row gutter={24}>
                        <Col span={16}>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Nome da obra" required>
                                        <Input value={this.props.getValue('name_work')} onChange={event => this.props.updateDataValue('name_work', event.target.value)} placeholder="Nome da obra" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Responsável" required>
                                        <Input value={this.props.getValue('responsible_work')} onChange={event => this.props.updateDataValue('responsible_work', event.target.value)} placeholder="Responsável" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Tipo de Obra">
                                        <Select
                                            value={this.props.getValue('type_work_new')}
                                            onChange={value => this.props.updateDataValue('type_work_new', value)}
                                            placeholder="Tipo de Obra" size="large" disabled={this.props.disabledFields}
                                        >
                                            <Select.Option value={null}>Selecione...</Select.Option>
                                            {(this.state.dataConfigs.usage || []).map((usage, index) => {
                                                return (
                                                    <Select.Option key={index} value={usage}>{usage}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={4}>
                                    <Form.Item label="CEP">
                                        <Input
                                            value={CreateMask('xx.xxx-xxx', this.props.getValue('cep_work'), 10, true)}
                                            onChange={event => this.props.updateDataValue('cep_work', event.target.value)}
                                            onBlur={async event => {
                                                const address = await searchAddress(event.target.value)
                                                if (address) {
                                                    this.props.updateDataValue('address_work', address.logradouro);
                                                    this.props.updateDataValue('complement_work', address.complemento);
                                                    this.props.updateDataValue('neighborhood_work', address.bairro);
                                                    this.props.updateDataValue('state_work', address.uf.toUpperCase());
                                                    this.props.updateDataValue('city_work', address.localidade);
                                                }
                                            }}
                                            placeholder="CEP" size="large" disabled={this.props.disabledFields}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={13}>
                                    <Form.Item label="Endereço">
                                        <Input value={this.props.getValue('address_work')} onChange={event => this.props.updateDataValue('address_work', event.target.value)} placeholder="Endereço" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item label="Bairro">
                                        <Input value={this.props.getValue('neighborhood_work')} onChange={event => this.props.updateDataValue('neighborhood_work', event.target.value)} placeholder="Bairro" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={14}>
                                    <Form.Item label="Complemento">
                                        <Input value={this.props.getValue('complement_work')} onChange={event => this.props.updateDataValue('complement_work', event.target.value)} placeholder="Complemento" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item label="Cidade">
                                        <Input value={this.props.getValue('city_work')} onChange={event => this.props.updateDataValue('city_work', event.target.value)} placeholder="Cidade" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item label="Estado">
                                        <Input value={this.props.getValue('state_work')} onChange={event => this.props.updateDataValue('state_work', event.target.value)} placeholder="Estado" size="large" disabled={this.props.disabledFields} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={8}>
                            <div className='note-box'>
                                <div className='note-title'>Instruções:</div>
                                <div>{
                                    ((this.state.dataConfigs && this.state.dataConfigs.proposal_form_notes_obra) || [])
                                        .map(paragraph => <p>{paragraph}</p>)
                                }</div>
                            </div>
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={24}>
                        <Col span={5}>
                            <Form.Item label="Estágio da Obra" required>
                                <Select value={this.props.getValue('work_stage')} onChange={value => this.props.updateDataValue('work_stage', value)} placeholder="Estágio da Obra" size="large" disabled={this.props.disabledFields}>
                                    <Select.Option value={null}>Selecione...</Select.Option>
                                    {(this.state.workStageData || []).map((ParseWorkStage, regIndex) => {
                                        return (
                                            <Select.Option key={regIndex} value={ParseWorkStage.id}> {ParseWorkStage.get('name')} </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Previsão da Compra" required>
                                <Select value={this.props.getValue('prevision_buy')} onChange={value => this.props.updateDataValue('prevision_buy', value)} placeholder="Previsão da compra" size="large" disabled={this.props.disabledFields}>
                                    <Select.Option value={null}>Selecione...</Select.Option>
                                    {(this.state.previsionBuyData || []).map((ParseWorkStage, regIndex) => {
                                        return (
                                            <Select.Option key={regIndex} value={ParseWorkStage.id}> {ParseWorkStage.get('name')} </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Inauguração da Obra">
                                <DatePicker
                                    value={moment(this.props.getValue('opening')).isValid() ? moment(this.props.getValue('opening')) : null}
                                    format='DD/MM/YYYY' onChange={event => this.props.updateDataValue('opening', event.toString())}
                                    style={{ width: '100%' }} placeholder="Inauguração da Obra" size="large" disabled={this.props.disabledFields}
                                    locale={pt_BR}
                                    showToday={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label='Latitude'>
                                <Input
                                    value={this.props.getValue('generalInfo') && this.props.getValue('generalInfo')['workAddressLat']}
                                    onChange={async value => {
                                        const generalInfo = {
                                            ...this.props.getValue('generalInfo'),
                                            workAddressLat: value.target.value
                                        }
                                        await this.props.updateDataValue('generalInfo', generalInfo)
                                    }}
                                    placeholder="Latitude" size="large" disabled={this.props.disabledFields}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label='Longitude'>
                                <Input
                                    value={this.props.getValue('generalInfo') && this.props.getValue('generalInfo')['workAddressLong']}
                                    onChange={async value => {
                                        const generalInfo = {
                                            ...this.props.getValue('generalInfo'),
                                            workAddressLong: value.target.value
                                        }
                                        await this.props.updateDataValue('generalInfo', generalInfo)
                                    }}
                                    placeholder="Longitude" size="large" disabled={this.props.disabledFields}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <h2>Contato obra</h2>
                    <p>Preencha pelo menos um dos contatos</p>

                    {
                        Date.parse(this.props.getValue('createdAt')) > Date.UTC(2022, 5, 2) ? // Data da atualização da lógica dos campos de contatos
                            <ContactsFields
                                contactKey='work'
                                value={this.props.getValue('workContacts')}
                                updateContacts={value => this.props.updateDataValue('workContacts', value)}
                            />
                            :
                            <Fragment>

                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item label="Contato de compras">
                                            <Input
                                                value={this.props.getValue('contact_obra_compras')}
                                                onChange={event => this.props.updateDataValue('contact_obra_compras', event.target.value)} placeholder="Contato de compras" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Cargo">
                                            <Input
                                                value={this.props.getValue('cargo_obra_compras')}
                                                onChange={event => this.props.updateDataValue('cargo_obra_compras', event.target.value)} placeholder="Cargo" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Telefone">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_obra_compras'))} onChange={event => this.props.updateDataValue('phone_obra_compras', event.target.value)} placeholder="Telefone" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Celular">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_cel_obra_compras'))} onChange={event => this.props.updateDataValue('phone_cel_obra_compras', event.target.value)} placeholder="Celular" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Whatsapp">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_whatsapp_obra_compras'))} onChange={event => this.props.updateDataValue('phone_whatsapp_obra_compras', event.target.value)} placeholder="Whatsapp" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <
                                            // @ts-ignore
                                            Form.Item label="E-mail" {...ValidateEmail(this.props.getValue('email_obra_compras') || '')}>
                                            <Input
                                                value={this.props.getValue('email_obra_compras')}
                                                onChange={event => this.props.updateDataValue('email_obra_compras', event.target.value)} placeholder="E-mail" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item label="Contato de engenharia">
                                            <Input
                                                value={this.props.getValue('contact_obra_eng')}
                                                onChange={event => this.props.updateDataValue('contact_obra_eng', event.target.value)} placeholder="Contato de engenharia" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Cargo">
                                            <Input
                                                value={this.props.getValue('cargo_obra_eng')}
                                                onChange={event => this.props.updateDataValue('cargo_obra_eng', event.target.value)} placeholder="Cargo" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Telefone">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_obra_eng'))} onChange={event => this.props.updateDataValue('phone_obra_eng', event.target.value)} placeholder="Telefone" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Celular">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_cel_obra_eng'))} onChange={event => this.props.updateDataValue('phone_cel_obra_eng', event.target.value)} placeholder="Celular" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Whatsapp">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_whatsapp_obra_eng'))} onChange={event => this.props.updateDataValue('phone_whatsapp_obra_eng', event.target.value)} placeholder="Whatsapp" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <
                                            // @ts-ignore
                                            Form.Item label="E-mail" {...ValidateEmail(this.props.getValue('email_obra_eng') || '')}>
                                            <Input
                                                value={this.props.getValue('email_obra_eng')}
                                                onChange={event => this.props.updateDataValue('email_obra_eng', event.target.value)} placeholder="E-mail" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={6}>
                                        <Form.Item label="Contato de gerência">
                                            <Input
                                                value={this.props.getValue('contact_obra_management')}
                                                onChange={event => this.props.updateDataValue('contact_obra_management', event.target.value)} placeholder="Contato de gerência" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Cargo">
                                            <Input
                                                value={this.props.getValue('cargo_obra_management')}
                                                onChange={event => this.props.updateDataValue('cargo_obra_management', event.target.value)} placeholder="Cargo" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Telefone">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_obra_management'))} onChange={event => this.props.updateDataValue('phone_obra_management', event.target.value)} placeholder="Telefone" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Celular">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_cel_obra_management'))} onChange={event => this.props.updateDataValue('phone_cel_obra_management', event.target.value)} placeholder="Celular" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item label="Whatsapp">
                                            <Input value={CreatePhoneMask(this.props.getValue('phone_whatsapp_obra_management'))} onChange={event => this.props.updateDataValue('phone_whatsapp_obra_management', event.target.value)} placeholder="Whatsapp" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <
                                            // @ts-ignore
                                            Form.Item label="E-mail" {...ValidateEmail(this.props.getValue('email_obra_management') || '')}>
                                            <Input
                                                value={this.props.getValue('email_obra_management')}
                                                onChange={event => this.props.updateDataValue('email_obra_management', event.target.value)} placeholder="E-mail" size="large" disabled={this.props.disabledFields} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Fragment>
                    }
                </Spin>
            </div>
        )
    }

}